import React, { useState } from 'react';
import './styles/Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add form submission logic here
        console.log('Form submitted:', formData);
    };

    return (
        <section className="contact">
            <div className="container">
                <h2>Contact Us</h2>
                <p>
                    We would love to hear from you! Whether you have a question, feedback, or just want to say hello, feel free to reach out to us.
                </p>
                <div className="contact-info">
                    <div className="contact-item">
                        <h3>Company Address</h3>
                        <p>
                            Prakriya  Technologies<br />
                            #292 Anjanadri Complex, 5th Floor, 11th Block<br />
                            Nagarabhavi, Bengaluru, Karnataka, 560072<br />
                        </p>
                    </div>
                    <div className="contact-item">
                        <h3>Email Us</h3>
                        <p>Email: <a href="mailto:info@prakriyatech.com">info@prakriyatech.com</a></p>
                    </div>
                </div>
                <div className="contact-form">
                    <h3>Send Us a Message</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">Subject:</label>
                            <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                rows="5"
                                required
                            ></textarea>
                        </div>
                        <button type="submit" className="submit-button">Send Message</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Contact;
