import React from 'react';
import './styles/Services.css';

const Services = () => {
    return (
        <section className="services" id="services">
            <h2>Our Services</h2>
            <div className="service-list">
                <div className="service-item">Service 1</div>
                <div className="service-item">Service 2</div>
                <div className="service-item">Service 3</div>
            </div>
        </section>
    );
};

export default Services;
