import React from 'react';
import './styles/Header.css';
import logo from '../logo prakriya 01.png'
const Header = () => {
    return (
        <header className="header">
            <nav className="navbar">
                <div className="logo">
                    <img src={logo} alt="" width="100px" height="50px" />
                </div>
                <ul className="nav-links">
                    <li><a href="/">Home</a></li>
                    <li><a href="about">About</a></li>
                    <li><a href="career">Career</a></li>
                    <li><a href="portfolio">Portfolio</a></li>
                    <li><a href="contact">Contact</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
