import React from 'react';
import './styles/Careers.css';

const Career = () => {
    return (
        <section className="career">
            <div className="container">
                <h2>Join Our Team</h2>
                <p>
                    At our company, we value innovation and creativity, and we are always looking for passionate individuals to join our dynamic team. We offer a collaborative work environment where your ideas can flourish and contribute to impactful projects.
                </p>
                <div className="career-opportunities">
                    <div className="career-item">
                        <h3>Software Developer</h3>
                        <p>
                            As a Software Developer, you'll tackle challenging projects and drive technological advancements. We seek individuals with strong coding skills, a problem-solving mindset, and a collaborative spirit.
                        </p>
                        <a href="#apply" className="apply-link">Apply Now</a>
                    </div>
                    <div className="career-item">
                        <h3>Project Manager</h3>
                        <p>
                            Our Project Managers ensure smooth project execution from start to finish. If you have exceptional organizational skills, leadership qualities, and experience managing complex projects, we’d love to hear from you.
                        </p>
                        <a href="#apply" className="apply-link">Apply Now</a>
                    </div>
                    <div className="career-item">
                        <h3>UI/UX Designer</h3>
                        <p>
                            As a UI/UX Designer, you'll create intuitive and visually appealing interfaces. We’re looking for individuals with a strong portfolio, a keen eye for detail, and expertise in design tools and user research.
                        </p>
                        <a href="#apply" className="apply-link">Apply Now</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Career;
