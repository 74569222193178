import React from 'react';
import './styles/Portfolio.css';

const Portfolio = () => {
    return (
        <section className="portfolio">
            <div className="container">
                <h2>Our Portfolio</h2>
                <div className="portfolio-items">
                    <div className="portfolio-item">
                        <h3>EaseKYC</h3>
                        <p>
                            EaseKYC is a comprehensive Know Your Customer (KYC) solution designed to streamline customer verification processes with enhanced security and efficiency.
                        </p>
                        <a href="https://easekyc.com" target="_blank" rel="noopener noreferrer" className="portfolio-link">
                            Learn More
                        </a>
                    </div>
                    <div className="portfolio-item">
                        <h3>Offline OCR Reading SDK for Electric Meters</h3>
                        <p>
                            Our Offline OCR Reading SDK provides accurate optical character recognition for electric meters, enabling seamless data extraction and offline functionality.
                        </p>
                        <a href="https://easekyc.com" target="_blank" rel="noopener noreferrer" className="portfolio-link">
                            Learn More
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Portfolio;
