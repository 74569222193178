import React from 'react';
import './styles/About.css';

const About = () => {
    return (
        <section id="about" className="about">
            <div className="container">
                <div className="company-overview">
                    <h1>About Us</h1>
                    <p>At Prakriya Technology, we are dedicated to providing top-notch software development services tailored to meet the unique needs of our clients. With years of experience and a team of skilled professionals, we deliver innovative solutions that drive business success.</p>
                </div>
                
                 <div className="services1">
                 <h2>Our Services</h2>
                 <div className="services">
                    
                    <div className="service-item">
                        <h3>Custom Software Development</h3>
                        <p>We create custom software solutions designed to fit your specific business requirements. Whether it's a desktop application or a complex enterprise system, we have the expertise to deliver high-quality solutions.</p>
                    </div>
                    <div className="service-item">
                        <h3>Web Development</h3>
                        <p>Our web development services include everything from creating responsive websites to developing web applications with the latest technologies. We ensure your online presence is both effective and engaging.</p>
                    </div>
                    <div className="service-item">
                        <h3>Mobile App Development</h3>
                        <p>We develop mobile applications for both iOS and Android platforms, focusing on user experience, performance, and scalability. Our apps are designed to meet the needs of today’s mobile users.</p>
                    </div>
                    <div className="service-item">
                        <h3>Consulting Services</h3>
                        <p>Our consulting services help businesses identify their technology needs and implement effective strategies. We provide expert advice on software architecture, system integration, and more.</p>
                    </div>
                </div>
                 </div>
               

                <div className="values">
                    <h2>Our Values</h2>
                    <div className="value-item">
                        <h3>Innovation</h3>
                        <p>We embrace innovation and continuously strive to adopt the latest technologies and methodologies to deliver the best solutions for our clients.</p>
                    </div>
                    <div className="value-item">
                        <h3>Integrity</h3>
                        <p>We conduct our business with the highest level of integrity, ensuring transparency and honesty in all our dealings.</p>
                    </div>
                    <div className="value-item">
                        <h3>Customer Satisfaction</h3>
                        <p>Our clients are at the heart of everything we do. We are committed to exceeding their expectations and delivering solutions that drive their success.</p>
                    </div>
                </div>

                <div className="cta">
                    <h2>Interested in Working With Us?</h2>
                    <p>Reach out to us to discuss how we can help your business achieve its technology goals.</p>
                    <a href="#contact" className="cta-button">Contact Us</a>
                </div>
            </div>
        </section>
    );
};

export default About;
