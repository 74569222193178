import React from 'react';
import './styles/Home.css';


const Home = () => {
    return (
        <section id="home" className="home">
            <div className="container">
                <div className="intro">
                    <h1>Welcome to Prakriya Technology</h1>
                    <p>We specialize in delivering top-notch software solutions and developing innovative products tailored to your needs.</p>
                </div>
                
                <div className="mission">
                    <h2>Our Mission</h2>
                    <p>At Prakriya Technology, our mission is to drive technological advancement and provide solutions that empower businesses. We are committed to excellence, innovation, and customer satisfaction.</p>
                </div>
                
               <div className="feature1">
               <h2>Why Choose Us?</h2>
               <div className="features">
                   
                    <div className="feature-item">
                        <h3>Expert Team</h3>
                        <p>Our team of experts brings years of experience in software development and product innovation.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Innovative Solutions</h3>
                        <p>We leverage cutting-edge technologies to deliver solutions that meet the evolving needs of our clients.</p>
                    </div>
                    <div className="feature-item">
                        <h3>Customer Focus</h3>
                        <p>We prioritize our clients' needs and work closely with them to ensure their goals are achieved.</p>
                    </div>
                </div>
               </div>
                
                <div className="testimonials">
                    <h2>What Our Clients Say</h2>
                    <div className="testimonial-item">
                        <p>"The team at Prakriya Technology exceeded our expectations with their innovative approach and dedication."</p>
                        <cite>- Rahul</cite>
                    </div>
                    <div className="testimonial-item">
                        <p>"Their software solutions are top-notch, and their customer service is exceptional."</p>
                        <cite>-Anusha</cite>
                    </div>
                </div>
                
                <div className="cta">
                    <h2>Ready to Start Your Project?</h2>
                    <p>Contact us today to discuss how we can help bring your ideas to life!</p>
                    <a href="#contact" className="cta-button">Get in Touch</a>
                </div>
            </div>
        </section>
    );
};

export default Home;
